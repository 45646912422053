import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import { GatsbyImage } from "gatsby-plugin-image";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Team | Assurance Capital"
        description="Meet the Assurance Capital investment team. We help companies grow by supplying capital, providing solid business advice, and networking. Contact us today."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 pt-10 md:mb-32 md:pt-20">
        <div className="container">
          <header className="mx-auto mb-14 max-w-[680px] text-center md:mb-20">
            <h1>Our Investment Team</h1>
            <p>
              We help companies grow by supplying capital and offering business
              owners our experience. We also connect you with our network of
              contacts obtained by operating our own businesses.
            </p>
          </header>

          <div className="mx-auto grid max-w-[380px] gap-y-12 sm:max-w-none sm:grid-cols-2 sm:gap-x-6 md:grid-cols-3 md:gap-y-14 lg:grid-cols-4 lg:gap-x-10">
            {data.teamMembers.edges.map(({ node }, i) => {
              return (
                node.headshot && (
                  <Link
                    to={`/${node.slug.current}/`}
                    className="group block no-underline"
                    key={i}
                  >
                    <div className="mb-6 overflow-hidden">
                      <GatsbyImage
                        image={node.headshot.asset.gatsbyImageData}
                        loading="lazy"
                        width={380}
                        className="scale-100 transform transition-all duration-500 ease-linear md:group-hover:scale-105"
                      />
                    </div>
                    <div>
                      <div className="heading-five mb-0">{node.name}</div>
                      {/* <div className="text-sm font-bold uppercase tracking-wide text-gray-400">
                        {node.title}
                      </div> */}
                    </div>
                  </Link>
                )
              );
            })}
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
    teamMembers: allSanityTeamMember(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          name
          slug {
            current
          }
          title
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 380)
            }
          }
        }
      }
    }
  }
`;

export default Page;
